// .state-tenants
//   background-color: #c2c2c2 !important

// .state-dashboard
//   background-color: transparent !important
//   background-image: url(../images/atp-gene-com-layout-bg.png) !important
//   background-size: cover !important
//   background-repeat: no-repeat !important
//   background-position: 0 0 !important

// md-toast.md-top
//   z-index: 999999 !important

// .state-dashboard
//   md-grid-tile
//     background-color: transparent !important
//     border: 2px solid #fff !important

//     md-icon
//       font-size: 2em !important
//       margin: 0 !important
//       bottom: 20px !important
//       position: absolute !important
//       left: 20px !important

//     md-grid-tile-footer
//       border-bottom-left-radius: 0 !important
//       border-bottom-right-radius: 0 !important
//       box-shadow: none !important
//       background: transparent !important
//       top: 0px
//       height: 100%
//       width: 97% !important

//       figcaption
//         position: absolute
//         top: 0
        
//         h3
//           font-size: 1.8em
//           font-weight: bold
//           margin-top: 20px


body > [ui-view]
  background: transparent url(/images/bg-pillar.jpeg) 0% 0% no-repeat padding-box !important
  background-size: cover !important

.state-tenants
  background-color: #c2c2c2 !important

md-toast.md-top
  z-index: 999999 !important

.state-dashboard
  md-grid-tile
    background-color: transparent !important
    border: 2px solid #fff !important

    md-icon
      font-size: 2em !important
      margin: 0 !important
      bottom: 20px !important
      position: absolute !important
      left: 20px !important

    md-grid-tile-footer
      border-bottom-left-radius: 0 !important
      border-bottom-right-radius: 0 !important
      box-shadow: none !important
      background: transparent !important
      top: 0px
      height: 100%
      width: 97% !important

      figcaption
        position: absolute
        top: 0

        h3
          font-size: 1.8em
          font-weight: bold
          margin-top: 20px
