@import "login"
@import "checklist"
@import "dashboard"
@import "quizzes"
// @import "roadmap"

// override widget tile description style
.widget .widget-label
  font-size: 1em !important
  
.widget .widget-description 
  text-transform: none !important
  font-size: 0.8em !important
  padding: 10px 5px 5px 5px !important
  line-height: 1.5em !important

// disclaimer
.disclaimer-overlay 
  .mat-dialog-container 
    .mat-dialog-content 
      .dialog-message
        margin: 100px auto

.disclaimer-overlay 
  .mat-dialog-container 
    .dialog-buttons
      top: -90px !important
   
.disclaimerTop
  color: transparent
  background-color: transparent
  text-decoration: none

.pillarDisclaimer
  color: #fff !important
  font-size: 16px
  text-transform: none
  .top
    color: #fff 
    h1
      color: #fff
      font-size: 18px

  .content
    margin-top: 150px
    h2
      font-size: 24px
      color: #fff

    h3
      font-size: 18px
      color: #fff
      margin-bottom: 0

    .body-content
      text-align: left
    .body-content:last-child
      ul
        list-style-type: none
    .boco
      margin-bottom: 0
    .bocoTerms   
      margin-top: 50px
      text-align: left

body
  background-image: url('/brand/eboni/assets/images/splash.png')

.dynamicLink, .dynamic-link, .search-container
  display: none !important

.navbar-search button.md-icon-button, .navbar-search md-icon
  display: none !important

.modal.fade.ng-isolate-scope.modal-video.in .modal-dialog
  width: 50% !important
// --- if iphone media query is needed ---
@media only screen and (min-device-width: 375px) and (max-device-width: 667px)
  .navbar-fixed-top .navbar-search
    right: 3.7em
  .navbar-fixed-top .navbar-logo-tenant
    transform: scale(0.6, 0.6)!important
  .disclaimer
    // font-size: 0.36em !important
  .modal-quiz
    quiz-question
      .quiz-buttons
        button.btn-lg
          font-size: 12px !important
  .modal-quiz
    .quiz-buttons
      bottom: 15% !important
  .modal-quiz
    .quiz-question
      height: 71% !important
.iphone-toolbar-content
  .flex-10
    max-height: 40px
    .navbar-back-to-delphire > li > a
      padding-top: 4px

#delphire-nav-bar
  .mat-sidenav
    background: transparent linear-gradient(180deg, #e30047 0%, #0d2e4e 100%) 0% 0% no-repeat padding-box

.space-view, .layout-view
  background-image: url('../assets/images/background.jpg') !important
  background-position: 0 0 !important
  background-repeat: no-repeat !important
  background-size: cover !important
  .mat-grid-tile
    border: 1px solid #061d49
    .mat-grid-tile-content
      .layout
        color: #061d49

footer
  .disclaimer
      background: #061d49
  background: #061d49
  .footer-bottom
    height: 5px
    .f1
      background-color: #e30047
      width: 80%
      @media (max-width: 400px)
        display: none
    .f2
      background-color: #e30047
      width: 15%
    .f3
      background-color: #e30047
      // width: 2vw


// color a caret
.md-select-value .md-select-icon:after
  color: #04407D !important

// prevent genentech from making font sizing decisions...
.new-ui.library .aside .bottom .file-description .description 
  h1, h2, h3, h4, h5, h6, li, blockquote
    font-size: 0.8em !important
    font-weight: normal !important

// need to see the link
.roadmap-item-description
  p 
    color: #000 !important
    a 
      color: #195695 !important
      text-decoration: underline !important
    a:active, a:hover
      color: #195695 !important
      text-decoration: underline !important
      
.course-builder-item-description
  p 
    color: #000 !important
    a 
      color: #195695 !important
      text-decoration: underline !important
    a:active, a:hover
      color: #195695 !important
      text-decoration: underline !important

.mat-sidenav-container  
  .mat-nav-list
    .myLearning, .otherSpaces, .notifications
      display: none !important

@media only screen and (min-device-width: 375px) and (max-device-width: 1024px)
  .my-learnings
    .header-table
      .table-container
        -webkit-overflow-scrolling: touch
        // height: 480px

        .learning-item-progress
          .md-bar2
            // height: 7px !important
