.widgetchecklist
	width: 100% !important
	height: 709px
	position: relative
	background-size: cover
	background-repeat: no-repeat
	background-position: center

.widgetheader
	text-transform: uppercase
	color: #fff
	text-align: center
	height: 78px !important
	font-size: 18px
	padding: 0 15px
	margin-bottom: 0px

.progressreading
	background: url(/images/icons/roadmapICN-reading.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressassessment
	background: url(/images/icons/roadmapICN-assesment.png) no-repeat center #850b6f
	background-size: 30px 30px

.progresscall
	background: url(/images/icons/roadmapICN-call.png) no-repeat center #850b6f
	background-size: 30px 30px

.progresscompliancetraining
	background: url(/images/icons/roadmapICN-compliancetraining.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressconverge
	background: url(/images/icons/roadmapICN-converge.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressdataviz
	background: url(/images/icons/roadmapICN-dataviz.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressipad
	background: url(/images/icons/roadmapICN-ipad.png) no-repeat center #850b6f
	background-size: 30px 30px

.progresslink
	background: url(/images/icons/roadmapICN-link.png) no-repeat center #850b6f
	background-size: 30px 30px

.progresslist
	background: url(/images/icons/roadmapICN-list.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressmeeting
	background: url(/images/icons/roadmapICN-meeting.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressverbalizer
	background: url(/images/icons/roadmapICN-verbalizer.png) no-repeat center #850b6f
	background-size: 30px 30px

.progresswebex
	background: url(/images/icons/roadmapICN-webex.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressmodules
	background: url(/images/icons/roadmapICN-modules.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressdiscussion
	background: url(/images/icons/roadmapICN-discussion.png) no-repeat center #850b6f
	background-size: 30px 30px

.progresspodcasts
	background: url(/images/icons/roadmapICN-podcast.png) no-repeat center #850b6f
	background-size: 30px 30px

.progressbiostats
	background: url(/images/icons/roadmapICN-biostat.png) no-repeat center #850b6f
	background-size: 30px 30px

.progresstraining
	background: url(/images/icons/roadmapICN-training.png) no-repeat center #850b6f
	background-size: 30px 30px