body #a0-lock.a0-theme-default .a0-panel
  .a0-icon-container
    .a0-image
      background: url(/images/brand-logo.png) no-repeat center
      background-size : contain !important

  button.a0-primary.a0-next
    background-color: #67909e !important
    &::after
      // content: 'Enviar'
      color: white
      background: none
      font-family: "Avenir Next",Avenir,-apple-system,BlinkMacSystemFont,Roboto,Hevetica,sans-serif
      font-weight: normal
      font-size: 18px
      letter-spacing: normal
      text-indent: 0
      text-align: center
      text-transform: none
      line-height: normal
      height: auto
      width: auto
body #a0-lock.a0-theme-default .a0-popup .a0-overlay
  @media (max-width: 400px) 
    background: url(/images/splash.png) no-repeat center
    background-size : 100% 100% !important
    
.library-item-preview
  background: none !important
	
.library-hold-list .library-item .library-item-meta .library-item-label
  font-size: 1.2em !important


.library-item .library-item-meta .library-item-label
  margin-top: 0px !important

.library-item-preview .library-item .library-item-content-alternate
  position: relative !important
  bottom: 40px !important
